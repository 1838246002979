<template>
<v-container>
  <v-card>
    <v-card-title>Configuración de Notificaciones</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-2">
      <v-list class="py-0">
        <v-list-item
          v-for="(notification, index) in notificationTypes"
          :key="notification.id"
        >
          <v-list-item-content>
            <v-list-item-title>{{ notification.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ notification.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              v-model="notification.enabled"
              @change="updateNotificationSettings"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <!-- Permission Request -->
      <div v-if="permissionStatus === 'default'" class="mt-4">
        <v-alert type="info" dense>
          Necesitas habilitar los permisos de notificaciones.
        </v-alert>
        <v-btn color="primary" @click="requestNotificationPermissions">
          Solicitar Permisos
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
  </v-container>
</template>

<script>
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { Device } from "@capacitor/device";

export default {
  data() {
    return {
      notificationTypes: [
        {
          id: "new_message",
          name: "Nuevos Mensajes",
          description: "Recibe notificaciones de nuevos mensajes",
          enabled: true,
        },
        {
          id: "task_reminder",
          name: "Recordatorios de Tareas",
          description: "Recibe notificaciones de recordatorios de tareas",
          enabled: true,
        },
        {
          id: "exercise_activity",
          name: "Actividad de Ejercicios",
          description: "Recibe notificaciones de actividad de ejercicios",
          enabled: true,
        },
        {
          id: "system_notifications",
          name: "Notificaciones del Sistema",
          description: "Recibe notificaciones del sistema",
          enabled: true,
        },
      ],
      loading: false,
      permissionStatus: Notification.permission, // 'default', 'granted', 'denied'
    };
  },
  methods: {
    async loadNotificationSettings() {
      const db = getFirestore();
      const userId = this.$store.state.Auth.token.claims.user_id;
      const messagingRef = doc(db, "messaging", userId);

      try {
        const docSnap = await getDoc(messagingRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.config) {
            // Update notificationTypes.enabled based on data.config
            this.notificationTypes.forEach((notification) => {
              if (data.config[notification.id] !== undefined) {
                notification.enabled = data.config[notification.id];
              }
            });
          }
        } else {
          // Create a new messaging document with default settings
          const config = {};
          this.notificationTypes.forEach((notification) => {
            config[notification.id] = notification.enabled;
          });
          await setDoc(messagingRef, { config });
        }
      } catch (error) {
        console.error("Error loading notification settings:", error);
      }
    },
    async updateNotificationSettings() {
      const db = getFirestore();
      const userId = this.$store.state.Auth.token.claims.user_id;
      const messagingRef = doc(db, "messaging", userId);

      try {
        const config = {};
        this.notificationTypes.forEach((notification) => {
          config[notification.id] = notification.enabled;
        });
        await updateDoc(messagingRef, { config });
      } catch (error) {
        console.error("Error updating notification settings:", error);
      }
    },
    async requestNotificationPermissions() {
      try {
        const permission = await Notification.requestPermission();

        this.permissionStatus = permission;

        if (permission === "granted") {
          const messaging = getMessaging();
          const token = await getToken(messaging, {
            vapidKey:
              "BGCHz5qHsrrI5SveIUNCs5OS2dsNNS1KIYPDAI8NKe_kcLPUDmuSHwKvUz-Q-AcN2Aw2YId8smvWiRfd6ewvirM", // Replace with your actual VAPID key
          });

          const deviceId = await this.getDeviceId();
          const db = getFirestore();
          const userId = this.$store.state.Auth.token.claims.user_id;
          const messagingRef = doc(db, "messaging", userId);
          const snapshot = await getDoc(messagingRef);

          if (snapshot.exists()) {
            let tokenArray = snapshot.data().tokens || [];

            // Check if the token for this device already exists
            const existingDevice = tokenArray.find(
              (entry) => entry.deviceId === deviceId
            );

            if (existingDevice) {
              if (existingDevice.token !== token) {
                // Update the token if it has changed
                existingDevice.token = token;
                await updateDoc(messagingRef, { tokens: tokenArray });
              }
            } else {
              // Add new device and token
              tokenArray.push({ deviceId, token });
              await updateDoc(messagingRef, { tokens: tokenArray });
            }
          } else {
            // Create a new document if it doesn't exist
            await setDoc(messagingRef, {
              tokens: [{ deviceId, token }],
              config: this.getConfigObject(),
            });
          }
        } else {
          // Handle denied permissions
          console.warn("Notification permissions denied");
        }
      } catch (error) {
        this.requestNotificationPermissions();
        console.error("Error requesting notification permissions:", error);
      }
    },
    async getDeviceId() {
      const info = await Device.getId();
      return info.uuid || "web"; // Fallback to 'web' if UUID is not available
    },
    getConfigObject() {
      const config = {};
      this.notificationTypes.forEach((notification) => {
        config[notification.id] = notification.enabled;
      });
      return config;
    },
  },
  mounted() {
    this.loadNotificationSettings();
    this.permissionStatus = Notification.permission;
  },
};
</script>

<style scoped>
/* You can add your styles here if needed */
</style>
